import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log("Connected to giftcard Controller");
    (async () => {
      const payments = Square.payments(
        window.App.SQUARE_APP_ID,
        window.App.SQUARE_LOCATION_ID
      )
      const giftCardOptions = {
        style: {
          input: {
            backgroundColor: 'white'
          }
        }
      };
      try {
        const giftCard = await payments.giftCard(giftCardOptions);
        await giftCard.attach('#giftcard')
        const payButton = document.getElementById('giftcardpay');
        payButton.addEventListener('click', async () => {
          const result = await giftCard.tokenize();
          const cardNonce = result.token;
          console.log(cardNonce);

          this.sendGiftData(cardNonce);
        })
      }
      catch (e) {
        console.error(e)
      }
    })();
  }

  async sendGiftData(cardNonce) {
    this.element.querySelector("#giftcardpay").disabled = true
    try {
      const response = await fetch('/payments/process_gift_payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ card_nonce: cardNonce, id: document.getElementById('order_id').textContent })
      });
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.success) {
          if (responseData.hasOwnProperty('url')){
            window.location.href = responseData.url;
          } else {
            let remainingAmount = responseData.remaining_amount;
            remainingAmount = (parseFloat(remainingAmount) / 100).toFixed(2);

            const errorMessage = document.getElementById('gcMessage')
            errorMessage.textContent = ''
            const cardMessage = document.getElementById('cardMessage');
            cardMessage.style.color = 'green'
            cardMessage.textContent = "Gift card is applied successfully! Your remaining amount is $" + remainingAmount + '. Please pay via card.';
            cardMessage.style.display = 'block';
            this.element.querySelector("#gifttoggle").style.display = 'none';
            this.element.querySelector("#cardtoggle").style.display = 'block';
            this.element.querySelector(".gc_square_logo").style.display = 'none';
            this.element.querySelector(".cc_square_logo").style.display = 'block';
            const button = this.element.querySelector(".btn-giftcard");
            button.textContent = button.textContent.trim() === "Gift card" ? "Credit Card" : "Gift card";
          }
        } else {
          const errorMessage = document.getElementById('gcMessage');
          errorMessage.style.color = 'red'
          errorMessage.textContent = responseData.error;
          errorMessage.style.display = 'block';
        }
      } else {
        console.error('Failed to process payment');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
    this.element.querySelector("#giftcardpay").disabled = false
  }

  toggleButtonText() {
    const button = this.element.querySelector(".btn-giftcard");
    button.textContent = button.textContent.trim() === "Gift card" ? "Credit Card" : "Gift card";
  }

  toggle() {
    const GiftForm = this.element.querySelector("#gifttoggle");
    const CardForm = this.element.querySelector("#cardtoggle");
    const CardSquareLogo = this.element.querySelector(".cc_square_logo")
    const GiftSquareLogo = this.element.querySelector(".gc_square_logo")
    CardSquareLogo.style.display = CardSquareLogo.style.display === 'none' ? 'block' : 'none';
    GiftSquareLogo.style.display = GiftSquareLogo.style.display === 'none' ? 'block' : 'none';
    GiftForm.style.display = GiftForm.style.display === 'none' ? 'block' : 'none';
    CardForm.style.display = CardForm.style.display === 'none' ? 'block' : 'none';
    this.toggleButtonText();
  }
}
